//color
$main: #c7b299;
$main2: #e3cbaf;
$bodytext: #69625a;
$bodyheadding: #9a8c7c;
$link: #5eb7e2;
$accent: #fd758f;

$black: #121212;

$blue: #addaf0;

$red: #f56d67;

$orange: #EC6902;

$gray: #EAEDEF;
$gray-light: #f0f4f7;

$yellow: #fff79b;
$yellow-light: #FFFECE;

$purple: #9D318E;

$facebook: #1877f2;
$twitter: #55acee;
$instagram: #b900b4;
$line: #00b900;

// //font
$basefont: 'Noto Sans JP',"Yu Gothic", YuGothic,-apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro",メイリオ, Meiryo ,Helvetica, sans-serif;
$en-font: 'Barlow Condensed',Helvetica;
$en-font2: 'Sacramento','Helvetica neue' ;
// $mincho: "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
$size-en-large: 6rem;
$size-en-medium: 4rem;
$size-large: 2.5rem;
$size-medium: 1.75rem;

$container-width: 1160px;